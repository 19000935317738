import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { 
	Container,
	Grid, 
	CardActions, 
	IconButton,
	Typography,
	Card
} from "@mui/material"; 
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import GenericForm from "../../components/forms/GenericForm"; 
import * as yup from "yup";
import * as ProcessActions from "../../redux/actions/process.actions";

const ManageComponent = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { defConfig, params, defLang, activeForm } = useSelector((state) => state.Login);
	
	const { componentConfig, processSuccess,  processError, requesting, processMessage,formFields, initialValues, validationSchema} = useSelector(
    (state) => state.Process
  );
  
  let  { id } = useParams();
  let  { componentName } = useParams();
	let  { action } = useParams();
	
  
  React.useEffect(()=>{
 console.log("dad", componentConfig,formFields)
		dispatch(ProcessActions.getFormData( { code :componentName, domainid:6, defLang:defLang, id:id } ))	
  },[componentConfig])
  
  return (
  
    <Grid className="fixed-container"  container >
    
    	<Grid container  sx={{px:0, my:2 }}>
    	
    		<IconButton color="primary" style={{
                  borderRadius:5
                  }} onClick={() => {
	              navigate(-1);
	            }}>
	              <ArrowCircleLeftIcon />
	            	<Typography variant="h5" color="primary"
	            	 style={{
                 
                  fontWeight: "bolder",
                  textTransform: "capitalize",

                }}
	            	>{" "+  defConfig[defLang][action]+" "+defConfig[defLang][componentName]}</Typography>
	            </IconButton>
    		
    		<Grid item xs={12}>
	    		{formFields.length>0 &&
					 	<GenericForm action={action} componentName={componentName} id={id} /> 
					}
			  </Grid>
    	 
    	</Grid>
    </Grid>
  );
}

ManageComponent.propTypes = {
//  window: ManageComponent.func,
};

export default ManageComponent;
