import React, { useState,useEffect,useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { Dialog, Button, Card, OutlinedInput, InputAdornment, Typography, ImageList ,TextareaAutosize , ImageListItem, FormLabel,RadioGroup, Radio, FormControlLabel, Container,FormControl,Select,MenuItem,InputLabel, Grid, TextField,  IconButton } from "@mui/material";
import Loading from "../../components/Loading";
import FileManagerDialog from "./FileManagerDialog";
import { find,  parseDataInfo, preloadForm, completeForm,getImagePath, simplePostData, simpleGetData } from '../../utils/Functions';
import { useSelector } from "react-redux";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add'; 
import RemoveIcon from '@mui/icons-material/Remove';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ComponentCardContainerDialog = (props) =>  {
	
	const {defLang, defConfig} = useSelector((state) => state.Login);	
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [isFaching,setIsFatching]    = useState(false)
	const [values,setValues]    = useState([])
	
	//for image manager
	const [openFileManagerDialog, setOpenFileManagerDialog] = React.useState(false);
	const [fieldName, setFieldName] = React.useState("");
	const [storeid, setStoreid] = React.useState("");
	const [product, setProduct] = React.useState("");
	
	const [storeids, setStoreids] = React.useState([]);
	
	const getOrganizations = async(event) => {
		var resp= await simplePostData( "/organizations/getOrganizationsByRol", {} ) 
  	//console.log( resp); 
  	var storeids= []
  	resp.result.map(function (key, item) {
			 storeids.push({ value: key.ORGANIZATIONID , text: key.NAME })
		}); 
		setStoreids(storeids)
		setStoreid(storeids[0].value)
	}
  React.useEffect(()=>{
	 	 setValues(props.value)
	 	 getOrganizations()
	 	 
	 	

	 	 
  },[]) 
  
   
		
	const deleteItem = (eventID) => {
  	  	
  	let _values = props.value
  	let pos = find(_values,  eventID, "ID" )
  	
  	//console.log(pos)
  	_values.splice(pos,1)
		let finalevent = { target: {name: props.fieldName, value:_values, type:"orderitems" }}
  	props.handleChange(finalevent)
  	
  }
  
  const updQuantityItem = (eventID, type) => {
  	  	
  	let _values = props.value
  	let pos = find(_values,  eventID, "ID" )
  	
  	_values[pos]["QUANTITY"] =  parseFloat(_values[pos]["QUANTITY"])  + ( type=="ADD" ? 1 : -1 )
  	if (_values[pos]["QUANTITY"] <1){
  		_values[pos]["QUANTITY"] =1
  	}
		let finalevent = { target: {name: props.fieldName, value:_values, type:"orderitems" }}
  	props.handleChange(finalevent)
  	
  }
	
	
  const addNewValue = (event) => {
   
  	let _values = props.value
  	let newId=0;
  	_values.map(function (key) {
  		if (newId< key.id){
  			newId=key.id
  		}
  	}); 
  	newId++
  	let newItem ={
  			id: newId,
		  	childs : [{
		  		id : 0,
		  		size: "",
		  		title: "",
		  		susbtitle: "",
		  		body: "",
		  		button: "",
		  		buttonlink:"",
		  		image: "",
		  		type:"text",
		  		imagepath:""
		  	}]
		  }
  	_values.push(newItem)
  	let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
  };
	
	const handleChange = (event) => {
		
		if (event.target.name =="storeid" ){
			setStoreid(event.target.value)
		}else if (event.target.name =="product" ){
			setProduct(event.target.value)
		}else{
			let field = event.target.name.split("_")
			let fieldid = field[0]
			let fieldname = field[1]
			let _values = props.value
			let pos = find(_values,  fieldid, "ID" )
			
			
			
				
				
			if (fieldname =="QUANTITY"){
				
				let qty = event.target.value
				if(!isNaN(qty)){
					_values[pos][fieldname]= (event.target.value)
				}
			}else{
				_values[pos][fieldname]= event.target.value	
			}
		 
			let finalevent = { target: {name: props.fieldName, value:_values, type:"orderitems" }}	
			
			props.handleChange(finalevent)
		}
	};
	
		const searchProducts =async (event) => {
		
	 
		
	};
  return (
    <Dialog fullWidth={true}  maxWidth="lg" scroll="paper" TransitionComponent={Transition} open={props.open} onClose={props.onClose} maxWidth="lg"  fullScreen={fullScreen} >
     	{isFaching ? <Loading />  : ""}
     	
     	<Card sx={{ p:2, mb:2}}>
			
				<Grid item xs={12} sx={{textAlign: "right"}} >
      		<IconButton onClick={props.onClose}  aria-label="close" color="primary">
					  <CloseIcon />
					</IconButton>
      	</Grid>
      	
				<Grid container px={1 }>
					<Grid item xs={12} mt={2} mb={4} >
						Busque un producto para agregarlo
					</Grid>
  			 	<Grid item xs={3} >
  			 		<FormControl fullWidth>
							<InputLabel>{defConfig[defLang].organizationlabel}</InputLabel>
        			<Select
                name={"storeid"}
						    value={storeid}
						    size="small"
						    label={defConfig[defLang].organizationlabel}
						    onChange={handleChange}>
						  	{storeids.map((value) => {
							    return(
							    	<MenuItem value={value.value}>{value.text}</MenuItem>
							    )
       						})}
						     
						  </Select>
					  </FormControl>
          </Grid>
          <Grid item xs={6} lg={4} pl={1} >
	      		 <TextField
              size="small"
              fullWidth 
              label={defConfig[defLang].productlabel}
              name="product"
              value={product}
              onChange={handleChange}
            />
        	</Grid>	
					<Grid item xs={2} pl={1} >
	      		<Button
              type="button"
              variant="contained"
              color="success"
              onClick={searchProducts}
              sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
          	>{defConfig[defLang].searchlabel}</Button>
        	</Grid>	 
          
		      	
        </Grid> 
     	</Card>
     	
			<Card sx={{ p:2, mt:2, mb:2}}>
			  
        {props.value!=null &&
	        <>         
		    		{props.value.map((item, index) => {
		    			return(
		        		
		          			<Grid mb={1} spacing={2}  container sx={{fontSize:12, borderBottom: "1px solid", borderColor:"success.main",    pb:1 }} >
		          			 	<Grid item xs={3} lg={1} px={1}>
		        						<img 
															key={"IMAGEPATH"+index} 
															name={"IMAGEPATH_"+index}  
															style={{maxHeight: 250, width:"100%", cursor:"pointer" }}
															 
															src={getImagePath(item.IMAGEPATH)} 
															alt={item.IMAGEPATH}
												/> 
				              </Grid>
				              
											<Grid item xs={6} lg={2} sx={{display:"flex", justifyContent: "left", alignItems:"center", alignContent:"center" }}>
							      	{item.PRODUCTNAME+ " - "+ item.SKU} 
				            	</Grid>	 
				            	<Grid item xs={3} lg={2} sx={{display:"flex", justifyContent: "left", alignItems:"center", alignContent:"center" }}>
							      	 {"Estado: "+item.STATUS} 
				            	</Grid>	
					            <Grid item xs={6} lg={2} sx={{display:"flex", justifyContent: "right", alignItems:"center", alignContent:"center" }}>

							      	<TextField
		                     	key={"PRICE"+index}
		                      
		                      size="small"
		                      label="Precio"
		                      value={item.PRICE} 
		                      name= {item.ID+"_PRICE"}
		                      onChange={handleChange}
		                    />
							      	
				            	</Grid>	 
				            	<Grid item xs={6} lg={2} sx={{display:"flex", justifyContent: "right", alignItems:"center", alignContent:"center" }}>
							      	<TextField
		                     	key={"_DISCOUNTPRICE"+index}
		                     
		                      size="small"		 
		                      label="Precio Descuento"
		                      value={item.DISCOUNTPRICE} 
		                      name= {item.ID+"_DISCOUNTPRICE"}
		                      onChange={handleChange}
		                    /> 
				            	</Grid>	 
			              	<Grid item xs={5} lg={2} sx={{display:"flex", justifyContent: "left", alignItems:"center", alignContent:"center" }}>
							      		<IconButton onClick={ ()=>{updQuantityItem( item.ID, "DEL" ) }}  aria-label="move" color="primary">
											  	<RemoveIcon />
												</IconButton>
							      	
							      		<TextField
		                     	key={"QUANTITY"+index}
		                       
		                      size="small"
		                       label="Cantidad"
		                      value={item.QUANTITY} 
		                      name= {item.ID+"_QUANTITY"}
		                      onChange={handleChange}
		                    />
		                    
								      	<IconButton onClick={ ()=>{updQuantityItem( item.ID, "ADD" ) }}  aria-label="move" color="primary">
												  	<AddIcon />
													</IconButton>
					            	</Grid>	  
				            	
				            	<Grid item xs={7} lg={1} sx={{display:"flex", justifyContent: "right", alignItems:"center", alignContent:"center" }}>
										   	<IconButton onClick={ ()=>{deleteItem( item.ID ) }}  aria-label="move" color="error">
											  	<DeleteIcon />
												</IconButton>
					            </Grid>	 
				            
		                </Grid>  
		                
		               
		              
			      	)
		      	})}
	      	</>
				}
				
				<Grid item xs={12} sx={{textAlign: "right"}} pr={2} pt={2}  >
      		<Button
            type="button"
            variant="contained"
            color="success"
            onClick={ props.onClose }
            sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
        	> {defConfig[defLang].savelabel}
        	</Button>
      	</Grid>
   		</Card> 
       
  </Dialog>
  );
};

ComponentCardContainerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ComponentCardContainerDialog;
