import * as React from 'react';
import PropTypes from 'prop-types';
import {  Grid, Card,Typography,Button } from "@mui/material";
 
import {  getImagePath } from '../../utils/Functions';

const CompCardContainer = (props) => {
    
  return (
     props.field.value.map((mastervalue, index) => {
							            			return(
							            		 		
									            		<Grid item xs={12} >
									            			<Card>
										            			<Grid container p={2} spacing={1}>
										            			
										            				{mastervalue.childs.map((value, index) => {
										            					return(
													            			<Grid item xs={12} md={12/mastervalue.childs.length} lg={12/mastervalue.childs.length }>
													            				<Grid container   sx={{ pb:1, pr:1, textAlign:"center" } } spacing={0}>

																					{value.updown==="IMAGEUP" &&
																						
																						<Grid item xs={12}>
																							<div className="form-image">
																								{value.imagepath!=="" &&
																									<img 
																										key={"imagepath"+index} 
																										name={"imagepath_"+index}  
																										style={{maxHeight:"250px", maxWidth:"100%",cursor:"pointer" }}																									
																										src={getImagePath(value.imagepath)} 
																										alt={value.imagepath}
																									/>
																								}
																							</div>
																						</Grid>
																					}

																					{value.title!=="" &&
																						<Grid item xs={12} >
																							<Typography variant="h5"
																									sx={{ 
																								fontFamily: "'Open Sans', sans-serif",
																								fontWeight: "bolder",
																								textTransform: "none",

																							}}>
																							{value.title}
																							</Typography>
																						</Grid>
																					}
																					{value.subtitle!=="" &&
																						<Grid item xs={12} >
																							<Typography variant="h6"
																									sx={{ 
																								fontFamily: "'Open Sans', sans-serif",
																								fontWeight: "bolder",
																								textTransform: "none",

																							}}>
																							{value.subtitle}
																							</Typography>
																						</Grid>
																					}
																					{value.body!=="" &&
																						<Grid item xs={12} >
																							<Typography variant="body"
																									sx={{ 
																								fontFamily: "'Open Sans', sans-serif",																								
																								textTransform: "none",

																							}}>
																							{value.body}
																							</Typography>
																						</Grid>
																					}
																					{value.button!=="" &&
																						<Grid item xs={12} justifyContent="center">
																						
																							<Button
																							type="button" 
																							variant="contained"
																							color="primary"
																							size="small"
																							sx={{textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}> 
																								{value.button}
																							</Button>
																						</Grid>	 
																					}

																					{value.updown==="IMAGEDOWN" &&
																						<Grid item xs={12}>
																							<div className="form-image">
																								{value.imagepath!=="" &&
																									<img 
																										key={"imagepath"+index} 
																										name={"imagepath_"+index}  
																										style={{maxHeight:"250px", maxWidth:"100%",cursor:"pointer" }}																									
																										src={getImagePath(value.imagepath)} 
																										alt={value.imagepath}
																									/>
																								}
																							</div>
																						</Grid>
																					}

																					{value.updown==="JUSTIMAGE" &&
																						<Grid item xs={12}>
																							<div className="form-image">
																								{value.imagepath!=="" &&
																									<img 
																										key={"imagepath"+index} 
																										name={"imagepath_"+index}  
																										style={{maxHeight:"250px", maxWidth:"100%",cursor:"pointer" }}																									
																										src={getImagePath(value.imagepath)} 
																										alt={value.imagepath}
																									/>
																								}
																							</div>
																						</Grid>
																					}
																					 
																		        </Grid>
															              </Grid>
														            	)
											      						})}  
														            
												              	 
																	      	
											                </Grid>  
										                </Card> 
													      	</Grid>
												      	)
											      	})
    
  );
};

CompCardContainer.propTypes = {};

export default CompCardContainer;

