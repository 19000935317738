import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { 
	Container,
	Card, 
	Grid, 
	CardActions, 
	Link ,
	Divider, 
	InputLabel, 
	OutlinedInput, 
	Checkbox, 
	FormControlLabel, 
	FormControl, 
	CssBaseline, 
	Avatar, 
	CardContent , 	
	Button , 
	Box , 
	Typography, 
	Input, 
	InputAdornment,
	Paper,
	InputBase,
	IconButton,
	TextField,
	
	  } from "@mui/material";

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import * as UserActions from "../../redux/actions/user.actions";
import { useFormik } from "formik";
import * as yup from "yup";
const FBButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor:  theme.palette.googleFacebookColor,
  '&:hover': {
    backgroundColor: theme.palette.googleFacebookColor,
  }, 
  paddingTop: 12,
  paddingBottom: 12,
  borderRadius: 10
}));

const FixedButton = styled(Button)(({ theme }) => ({   
  paddingTop: 12,
  paddingBottom: 12,
  borderRadius: 10
}));

const label = { inputProps: { 'aria-label': 'Checkbox agree' } };
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const validationSchema = yup.object({

	firstname: yup
    .string("Ingrese sus nombres")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Nombres requeridos"),
	lastname: yup
    .string("Ingrese sus apellidos")
    //.min(8, "Password should be of minimum 8 characters length")
    .required("Apellidos requeridos"),
  email: yup
    .string("Ingrese su email")
    .email("Ingrese un email v\u00E1lido")
    .required("Email requerido"),
	phonenumber: yup
    .string("Ingrese su n\u00FA de Tel\u00E9fono")
    .matches(phoneRegExp,"Ingrese un n\u00FAmero de Tel\u00E9fono v\u00E1lido")
    .min(7, 'Ingrese un n\u00FAmero de Tel\u00E9fono v\u00E1lido')
    .max(8, 'Ingrese un n\u00FAmero de Tel\u00E9fono v\u00E1lido')
    .required("N\u00FAmero de Tel\u00E9fono requerido"),
  password: yup
    .string("Ingrese su contrase\u00F1a")
    .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")
    
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[+-.!@#\$%\^&\*])(?=.{8,})/,
      "Debe contener 8 Caracteres, al menos 1 May\u00FAscula, 1 Min\u00FAscula, 1 N\u00FAmero y 1 Caracter Especial (Ej. # $ % & !)"
    )
    .required("Contrase\u00F1a requerida"), 
   
  password2: yup.string("Ingrese su contrase\u00F1a")
  	 .min(8, "La contrase\u00F1a debe contener al menos 8 caracteres")
     .oneOf([yup.ref('password'), null], 'Las contrase\u00F1as no coinciden'),
});

const RegisterForm = props => { 
	const dispatch = useDispatch();
	const { defConfig, params, defLang, activeForm } = useSelector((state) => state.Login);
	const [showPassword, setShowPassword] = React.useState(false)
	
	const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
	
	const formik = useFormik({
    initialValues: {
    	firstname:"test1",
      lastname:"test1",
      email: "test1@test.com",
      phonenumber: "2222222",
      password: "Test1234.",
      password2: "Test1234."
    },
    validationSchema: validationSchema,
    onSubmit: async(values) => {
    	values["usergroupid"] = 0
    	values["imagepath"] = ""
    	values["languageid"] = process.env.REACT_APP_DEF_LANGUAGE=="es-es"?3:2
    	values["organizationid"] = 0
    	
    	let captchaSuccess = true
    	
    	
      if (captchaSuccess){
      	dispatch(UserActions.CreateUser(values))
      }
    },
  });
	 
	 
  return (
				<Box
          sx={{
            mt: { xs:0, md:0 , lg:2 },
            ml: { xs:-2, md:0 , lg:2 },
            mr: { xs:-2, md:0 , lg:2 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        > 
          <form onSubmit={formik.handleSubmit}>
	          <Grid spacing={1} container >
	          	
	          	<Grid item xs={12} sx={{ pl:0,  display:{xs:"block", md:"none", lg:"none" } }}>
	          		<img style={{width: "100%"}}  src="/images/m-logo-top-panel.png"/>
	          	</Grid>
	          	
	          	<Grid item xs={12} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
	          		<Typography variant="h3"  sx={{mb:2}} fontWeight="bolder" color="primary">
				        	{defConfig[defLang].signup}
				        </Typography>
			         	<Typography variant="body1" sx={{mt:2, mb:4}} color="secondary" >
			            {defConfig[defLang].signupmessage}
			          </Typography>  
			          
	          		 <TextField
                      fullWidth
                     
                      label={defConfig[defLang].formfirstname}
                 
                      error={formik.touched.firstname && Boolean(formik.errors.firstname)}
		                	helperText={formik.touched.firstname && formik.errors.firstname}
                      name="firstname"
                      value={formik.values.firstname}
                      id="firstname"
                      onChange={formik.handleChange}
                      
                      endAdornment={
				              <InputAdornment position="end">
				                <IconButton 
				                  edge="end"
				                >
				                	<MailOutlineIcon />
				                </IconButton>
				              </InputAdornment>
				            } 
                    />
				    	</Grid>   
				    	
				    	<Grid item xs={12} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
	          	 <TextField
                      fullWidth
                     
                      label={defConfig[defLang].formlastname}
                 
                      error={formik.touched.lastname && Boolean(formik.errors.lastname)}
		                	helperText={formik.touched.lastname && formik.errors.lastname}
                      id="lastname"
				              name="lastname"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      
                      endAdornment={
				              <InputAdornment position="end">
				                <IconButton 
				                  edge="end"
				                >
				                	<MailOutlineIcon />
				                </IconButton>
				              </InputAdornment>
				            } 
                    />
	          		 
				    	</Grid>   
				    	
				    	
	        		<Grid item xs={12} md={6} lg={6} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
			        	<FormControl fullWidth variant="outlined">
				          <InputLabel htmlFor="email">{defConfig[defLang].formemail}</InputLabel>
				          <OutlinedInput
				          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
				            id="email"
				            name="email"
				            type="text"
				            
				            error={formik.touched.email && Boolean(formik.errors.email)}
		                helperText={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
				            
				            endAdornment={
				              <InputAdornment position="end">
				                <IconButton 
				                  edge="end"
				                >
				                	<MailOutlineIcon />
				                </IconButton>
				              </InputAdornment>
				            } 
				            label={defConfig[defLang].formemail}
				          />
				        </FormControl> 			
				        {formik.touched.email &&
					          <Typography variant="caption"   fontWeight="bolder" color="error">
						        	{formik.errors.email}
						        </Typography>
					        }	        
			        </Grid>
			        <Grid item xs={12} md={6} lg={6}  sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
			        	<FormControl fullWidth variant="outlined">
				          <InputLabel htmlFor="phonenumber">{defConfig[defLang].formphonenumber}</InputLabel>
				          <OutlinedInput
				          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
				            id="phonenumber"
				            name="phonenumber"
				            type="text"
				            
				            error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
		                helperText={formik.touched.phonenumber && formik.errors.phonenumber}
                    value={formik.values.phonenumber}
                    onChange={formik.handleChange}
                    
				            endAdornment={
				              <InputAdornment position="end">
				                <IconButton 
				                  edge="end"
				                >
				                	<LocalPhoneRoundedIcon />
				                </IconButton>
				              </InputAdornment>
				            } 
				            label={defConfig[defLang].formphonenumber}
				          />
				         
				        </FormControl> 				    
				         {formik.touched.phonenumber &&
					          <Typography variant="caption"   fontWeight="bolder" color="error">
						        	{formik.errors.phonenumber}
						        </Typography>
					        }    
			        </Grid>
			        
			        <Grid item xs={12} md={6} lg={6} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
			        	<FormControl fullWidth variant="outlined" >
				          <InputLabel htmlFor="password">{defConfig[defLang].formpassword}</InputLabel>
				          <OutlinedInput
				          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
				            id="password"
				            name="password"
				            type={showPassword ? 'text' : 'password'}
				            
				            error={formik.touched.password && Boolean(formik.errors.password)}
		                helperText={formik.touched.password && formik.errors.password}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    
				            endAdornment={
				              <InputAdornment position="end">
				                <IconButton
				                  aria-label="toggle password visibility"
				                  onClick={handleClickShowPassword}
				                  onMouseDown={handleMouseDownPassword}
				                  edge="end"
				                >
				                  {showPassword ? <LockOpenIcon/> : <LockIcon />}
				                </IconButton>
				              </InputAdornment>
				            } 
				            label={defConfig[defLang].formpassword}
				          />
				          
			        </FormControl>			        
			        {formik.touched.password &&
					          <Typography variant="caption"   fontWeight="bolder" color="error">
						        	{formik.errors.password}
						        </Typography>
					        }
			        </Grid>
			        <Grid item xs={12} md={6} lg={6} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
			        	<FormControl fullWidth variant="outlined" >
				          <InputLabel htmlFor="password2">{defConfig[defLang].formpassword2}</InputLabel>
				          <OutlinedInput
				          	sx={{ backgroundColor:"forms.bgLoginTextField" }}
				            id="password2"
				            name="password2"
				            type={showPassword ? 'text' : 'password'}
				            
				            error={formik.touched.password2 && Boolean(formik.errors.password2)}
		                helperText={formik.touched.password2 && formik.errors.password2}
                    value={formik.values.password2}
                    onChange={formik.handleChange}
                    
				            endAdornment={
				              <InputAdornment position="end">
				                <IconButton
				                  aria-label="toggle password visibility"
				                  onClick={handleClickShowPassword}
				                  onMouseDown={handleMouseDownPassword}
				                  edge="end"
				                >
				                  {showPassword ? <LockOpenIcon/> : <LockIcon />}
				                </IconButton>
				              </InputAdornment>
				            } 
				            label={defConfig[defLang].formpassword2}
				          />
				         
				        </FormControl>
				         {formik.touched.password2 &&
					          <Typography variant="caption"   fontWeight="bolder" color="error">
						        	{formik.errors.password2}
						        </Typography>
					        }
			        </Grid>
			        <Grid item xs={12} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
			        	<FormControlLabel
	                control={<Checkbox 
					                	
	      										defaultChecked
	      										sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
	      
	                	value="agree" color="primary" />}
	                label={defConfig[defLang].agreeTermsRegister}
	              />
	            </Grid>
			        <Grid item xs={12} sx={{mt:1, ml:{xs:3, md:0}, mr:{xs:5, md:0}}}>
	            	<FixedButton
	                type="submit"
	                fullWidth
	                variant="contained"
	                sx={{ mt: 2, mb: 2, textTransform:"initial", fontWeight: {xs:"bold", md:"normal"} , fontSize:{ xs :"0.9rem", md: "0.685rem" }}}
	            	>
	              	{defConfig[defLang].signup}
	             	</FixedButton>
					{/* 
	            	<Divider>   
	              	<Typography variant="caption" >
			              {defConfig[defLang].or}
			          	</Typography>
	            	</Divider>*/}
	            </Grid>
				{/* 
	            <Grid item xs={12} md={6} lg={6} sx={{mt:1 , ml:{xs:3, md:0}, mr:{xs:5, md:0} }}>	    
	               <FixedButton
		                type="button"
		                fullWidth
		                color="secondary"
		                variant="outlined"
		                sx={{ mt: 1, textTransform:"initial", fontWeight: {xs:"bold", md:"normal"} , fontSize:{ xs :"0.9rem", md: "0.685rem" } }}
		                startIcon={<GoogleIcon />}
		              >
		                {defConfig[defLang].formlogingoogle}
		              </FixedButton>	                  
	            </Grid>
	            <Grid item xs={12} md={6} lg={6} sx={{mt:1 , ml:{xs:3, md:0}, mr:{xs:5, md:0} }}>	    
	              <FBButton 
	                type="button"
	                fullWidth
	                variant="contained"
	                sx={{ mt: 1, textTransform:"initial", fontWeight: {xs:"bold", md:"normal"} , fontSize:{ xs :"0.9rem", md: "0.685rem" } }}
	                startIcon={<FacebookOutlinedIcon />}
	              >
	                {defConfig[defLang].formloginfacebook}
	              </FBButton>	                   
	        	  </Grid>
				*/}
	          </Grid>
	          <Grid container  sx={{ mt: 1, mb: 1}}>
	            <Grid item xs sx={{mt:1 , ml:{xs:3, md:0}, mr:{xs:5, md:0} }}>
	              <Link href="#" variant="body2" onClick={() => {dispatch(UserActions.activateForm("forgot")); }}>
	                {defConfig[defLang].forgotpassword}
	              </Link>
	            </Grid>
				{/* 
	            <Grid item  sx={{mt:1 , ml:{xs:3, md:0}, mr:{xs:5, md:0} }}>
	              <Link href="#" variant="body2" onClick={() => {dispatch(UserActions.activateForm("login")); }}>
	               {defConfig[defLang].gootologin}
	              </Link>
	            </Grid>*/}
	          </Grid>
	         </form>
      	</Box>
     
	
  )
};

export default RegisterForm;
