import React, { Component } from "react";
import { useFormik } from "formik";
import { Button, Card, OutlinedInput, InputAdornment, Typography, ImageList ,TextareaAutosize , ImageListItem, FormLabel,RadioGroup, Radio, FormControlLabel, Container,FormControl,Select,MenuItem,InputLabel, Grid, TextField,  IconButton } from "@mui/material";

import DatePicker, { registerLocale } from "react-datepicker";
/*
import DateTimePicker from './DateTimePicker'; 
<DateTimePicker 
											key={"field_"+field}
											date={props.filterConfig[field].value?props.filterConfig[field].value: new Date()} 
											name={field}
											label={props.filterConfig[field].label} 
											options={field.options} 
											isUpdatable= {field.isUpdatable===undefined?true: field.isUpdatable }
											updateDate={props.searchHandler} 
										/>
                    */
 
const Filters = props => {
/*
	const [refreshingData, setRefreshingData] = React.useState(false);
	const setFormDate =(field,date ) => {
		let fields = finalFormFields; 
		
		setRefreshingData(true)
		let pos = find(finalFormFields,field, "name"  )  	
	 	fields[pos].value = (date)
	 	
	 	setTimeout(() => {
    		setFinalFormFields(fields) 
			setRefreshingData(false)
    }, 1);
	}
	
 */{/*selected={props.searchHandler}*/}
 
 
 const [showMore,setShowMore]    = React.useState(false)
 const [refreshDate,setRefreshDate]    = React.useState(false)
 const [extraFields,setExtraFields]    = React.useState({})
 const formik = useFormik({
	initialValues: props.formData?.formValues?props.formData.formValues: props.initialValues,
	enableReinitialize:true,
	validateOnBlur: true,
 
		onSubmit: (values, {setSubmitting, setErrors, setStatus, resetForm} ) => {			 
			let finalValues = []
			Object.keys(values).map(function (key) {				 
				let field = 
					{ field: key, value: values[key], type: props.filterConfig[key].type }				 
				finalValues.push(field)
			}); 
 			props.searchHandler(finalValues)
		},
	});

	const setDates = (field,date ) => {
		
		let _extraFields = extraFields

		setRefreshDate(true) 
		_extraFields[field] = date
	//	console.log(formik.values, date )
		formik.values[field] = date

		setTimeout(() => {
			setExtraFields(_extraFields) 
			setRefreshDate(false) 
		}, 1);
	}

	React.useEffect(()=>{
		setExtraFields(props.initialValues)
	},[props])

	  return (  
		<form  onSubmit={formik.handleSubmit}  	>
		  	<div className="row">
					<div className="col-12">
			    	<div className="col-12">
				    	<span className="h3-responsive "> 
				    		<i className="fas fa-filter"/>  Filtros
				    	</span>
				    	<span className="or-line"/> 
			    	</div>
		    		{ Object.keys(props.filterConfig).map((field, index) => (index>4?"":
		    			
		    			<div className={"col-12 mt-"+ ( props.filterConfig[field].type=="DATE"?"0":"3"  ) }>
								 
									{props.filterConfig[field].type=="SELECT" &&
										 <FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">{  props.filterConfig[field].label}</InputLabel>
											<Select  key={"field_"+field}
												name={field} 
												defaultValue={props.filterConfig[field].value} 
												value={formik.values[field]}
												onChange={formik.handleChange}
												>
												{props.filterConfig[field].values.map((item) => {
													return(
													<MenuItem  key={"field_"+field+"_i"+item} value={item.value}>{item.text}</MenuItem>
													)
												})}
											</Select>
										</FormControl>
									}
									{props.filterConfig[field].type=="DATE" &&
										<>
										<label className="filter-label" key={"label_form_"+field}>{  props.filterConfig[field].label}  </label>	
										<DatePicker
											showYearDropdown
											showIcon
											selected={  refreshDate?null: extraFields[field] }
											className="custom-datepicker"
											maxDate={  new Date()   }
											yearDropdownItemNumber={50}
											locale="es"
											onChange={(date) => {  setDates( field,date ) }  }
 
											dateFormat="dd/MM/yyyy"
										/>
										</>

									}
									{props.filterConfig[field].type=="TEXT" &&
										<>

											<TextField
												key={"field"+index}
												fullWidth
												placeholder={field.placeholder?field.placeholder:""} 
												disabled={field.disabled || props.allDisabled}
												label={  props.filterConfig[field].label}	
												name={field}
												value={formik.values[field]}
												id={field.name}
												onChange={formik.handleChange}
												/>
 
										</>	 
									}
	          		 
	          	</div> 	 
		    		))
						}
						<div className="col-12 mt-1 mb-1">
						{Object.keys(props.filterConfig).length>=4? 	<a className="col-12 mt-1 mb-1 text-right no-button" name="showMore" onClick={ ()=> { setShowMore(!showMore)}  } >  <span style={{ color:"#909090", fontSize:14, textDecoration:"underline" }}> {showMore?"Menos ":"M\u00E1s "} Campos <i className= {"fas fa-angle-"+ (showMore?"up":"down") }   /></span> </a>:""}
						</div>
						{ Object.keys(props.filterConfig).map((field, index) => ( showMore && index>4?
		    			
		    			<div className="col-12 mt-3">
								 
									{props.filterConfig[field].type=="SELECT" &&
										
										<FormControl fullWidth>
											<InputLabel key={"fieldl"+field}>{  props.filterConfig[field].label}</InputLabel>
											<Select  key={"field_"+field}
													name={field} 
													value={formik.values[field]}
													onChange={formik.handleChange}
											  >
												{props.filterConfig[field].values.map((item) => {
													return(
													<MenuItem  key={"field_"+field+"_i"+item} value={item.value}>{item.text}</MenuItem>
													)
												})}
											</Select>
										</FormControl>
									}
									{props.filterConfig[field].type=="TEXT" &&
										<TextField
											key={"field"+index}
											fullWidth
											placeholder={field.placeholder?field.placeholder:""} 
											disabled={field.disabled || props.allDisabled}
											label={  props.filterConfig[field].label}	
											name={field}
											value={formik.values[field]}
											id={field.name}
											onChange={formik.handleChange}
										/>
									}
	          		 
	          		</div> 	
	          	:""
		    		))
		    		
						}
						
						<div className="col-12 mt-3"> 
							<button className="btn btn-success btn-block" name="search"
									 
									type="submit">Buscar</button> 
          				</div>

						<div className="col-12 mt-1"> 
							<button className="btn btn-secondary btn-block" name="refresh"
									onClick={()=>{props.searchHandler("refresh") }}
									type="submit">Refrescar</button> 
          				</div>
						  <div className="col-12 mt-1"> 
							<button className="btn btn-outlined-secondary btn-block" name="refresh"
									onClick={()=>{props.toggleFilter("") }}
									type="submit">Ocultar</button> 
          				</div>
						  
						 
				</div>
			</div>	 
		</form>
	  );
	 
};
export default Filters;
				 