import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container,Card, Grid, CardActions, Link ,Divider, InputLabel, OutlinedInput, Checkbox, FormControlLabel, FormControl, CssBaseline, Avatar, CardContent , 	Button , Box , Typography, Input, InputAdornment,Paper,InputBase,IconButton  } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import LoginForm from "../../components/forms/LoginForm";
import RegisterForm from "../../components/forms/RegisterForm";
import ForgotForm from "../../components/forms/ForgotForm";

import "./Login.css";

const Login = (props) => {
	const { defConfig, params, defLang, activeForm} = useSelector((state) => state.Login);
	const navigate = useNavigate();
  const dispatch = useDispatch();
  
   
	React.useEffect(()=>{
    
    document.getElementById("loginPanel").style.opacity = 0
    document.getElementById("registerPanel").style.opacity = 0
    
    if (activeForm == "login" ){ 
  		document.getElementById("loginPanel").style.zIndex= 2 
  		document.getElementById("loginPanel").style.opacity= 1 
    	
    	document.getElementById("registerPanel").style.zIndex = 1
    	document.getElementById("registerPanel").style.opacity = 0
    	
    	document.getElementById("forgotPanel").style.opacity = 0
    	document.getElementById("forgotPanel").style.zIndex = 1
    	
    	
    }else if (activeForm == "register" ){
    	document.getElementById("registerPanel").style.zIndex = 2
  		document.getElementById("registerPanel").style.opacity = 1
    	
    	document.getElementById("loginPanel").style.zIndex = 1
    	document.getElementById("loginPanel").style.opacity = 0
    	
    	document.getElementById("forgotPanel").style.opacity = 0
    	document.getElementById("forgotPanel").style.zIndex = 1
    	
    }else if (activeForm == "forgot" ){
    	document.getElementById("registerPanel").style.zIndex = 1
  		document.getElementById("registerPanel").style.opacity = 0
    	
    	document.getElementById("loginPanel").style.zIndex = 1
    	document.getElementById("loginPanel").style.opacity = 0
    	
    	document.getElementById("forgotPanel").style.opacity = 2
    	document.getElementById("forgotPanel").style.zIndex = 1
    	
    }
      
  },[activeForm])
   
  return (<>
   
  	<Box sx={{display:"flex", justifyContent:"center" }}>
			<Grid 
				sx={{backgroundColor:"#fff", 
						 height:"98vh", 
						 border: '1px solid #fff',
						 borderRadius: 2,
						 
						 mx:{ xs: 1.5, md:0, lg:0 }  
				}}
				xs={12} md={12} lg={10} xl={9} justifyContent="center"  alignItems="center" container  >
        <CssBaseline />
        <Grid
          item
          md={6} 
          lg={6} 
          sx={{ display: {xs:"none", md:"block", lg:"block" }}}
        >
        
        	<img style={{width: "96%"}}  src="/images/login-left-panel.png"/>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
           
          <div id="registerPanel" className="form-panel">
						<RegisterForm/>
				  </div> 
				  <div id="loginPanel" className="form-panel">
						<LoginForm/>
				  </div> 
				  
				  <div id="forgotPanel" className="form-panel">
						<ForgotForm/>
				  </div> 
				  
	      </Grid>
	    </Grid>
		</Box>
  
  
  </>  
  );
};

Login.propTypes = {};

export default Login;
