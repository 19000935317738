import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header/Header";
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Footer from "../components/footer/Footer";
import MobileNav from "../components/header/MobileNav";
import {useNavigate} from 'react-router-dom';
import { useSelector } from "react-redux";
const MainLayout = ({ openPanel, setOpenPanel }) => {
	const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
	const { usertoken } = useSelector(
    (state) => state.Login
  );
	const navigate = useNavigate();
  React.useEffect(()=>{
  	if (!usertoken){
  		navigate("/login");
  	}
  },[usertoken])
  return (<>
     <Box sx={{ display: 'flex' }}>
      <Header setOpenPanel={setOpenPanel} />
      <Box component="main" sx={{ width: '84%', minHeight:windowHeight-40 /*height: 768, overflowY: "scroll"*/ }} >
	      <Outlet />
      	
      </Box>
    </Box>
    <Footer />
    </>
  );
};

MainLayout.propTypes = {};

export default MainLayout;