import React, { useState,useEffect,useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import {
  Container,
  Typography,
  FormGroup,
  Grid,
  Card,
  Button, 
  IconButton,
  Popover ,
  TextField,
  Input,
  InputAdornment,
  Radio ,
  RadioGroup ,
  FormControlLabel,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import Loading from "../../components/Loading";
import FileManagerDialog from "./FileManagerDialog";
import { findObjName, find, getImagePath} from '../../utils/Functions';

import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ComponentCardContainerDialog = (props) =>  {
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [isFaching,setIsFatching]    = useState(false)
	const [values,setValues]    = useState([])
	
	//for image manager
	const [openFileManagerDialog, setOpenFileManagerDialog] = React.useState(false);
	const [fieldName, setFieldName] = React.useState("");
	
  React.useEffect(()=>{
		setValues(props.value)
  },[]) 
  
  const splitItem = (event) => {
  	  	
  	let field = event.target.name.split("_")
  	let fieldid = field[0]
  	let _values = props.value
		let pos = find(_values,  fieldid, "id" )
		let childs = _values[pos].childs		
		if (childs.length<4 ){
			let newId=0;
	  	childs.map(function (key) {
	  		if (newId< key.id){
	  			newId=key.id
	  		}
	  	}); 
  		newId++
			let newItem= {
		  		id : newId,
		  		size: "",
		  		title: "",
		  		susbtitle: "",
				updown: "IMAGEUP",
		  		body: "",
		  		button: "",
		  		buttonlink:"",
		  		image: "",
		  		type:"text",
		  		imagepath:""
		  }
		  childs.push ( newItem)
		}
		_values[pos].childs = childs		
		let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
  	
	}
	
	
	
	const deleteSplit = (event) => {
  	  	
  	let field = event.split("_")
  	let fieldid = field[0]
  	let fieldsubid = field[1]
  	let _values = props.value
		let pos = find(_values,  fieldid, "id" )
		let childs = _values[pos].childs
		let pos2 = find( _values[pos].childs,  fieldsubid, "id" )		 
		childs.splice(pos2,1)
		_values[pos].childs = childs
		let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
	
	}
	
	const deleteItem = (event) => {
  	  	
  	let field = event.target.name.split("_")
  	let fieldid = field[0]
  	let _values = props.value
  	let pos = find(_values,  fieldid, "id" )
  	 
  	_values.splice(pos,1)
		let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
  	
  }
	
  const addNewValue = (event) => {
   
  	let _values = props.value
  	let newId=0;
  	_values.map(function (key) {
  		if (newId< key.id){
  			newId=key.id
  		}
  	}); 
  	newId++
  	let newItem ={
  			id: newId,
		  	childs : [{
		  		id : 0,
		  		size: "",
		  		title: "",
		  		susbtitle: "",
				updown:"IMAGEUP",
		  		body: "",
		  		button: "",
		  		buttonlink:"",
		  		image: "",
		  		type:"text",
		  		imagepath:""
		  	}]
		  }
  	_values.push(newItem)
  	let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
  	props.handleChange(finalevent)
  };
	
	const handleChange = (event) => {
		
		let field = event.target.name.split("_")
		let fieldid = field[0]
		let fieldsubid = field[1]
		let fieldname = field[2]
		let _values = props.value
		let pos = find(_values,  fieldid, "id" )
		let pos2 = find( _values[pos].childs,  fieldsubid, "id" )
		
		 _values[pos].childs[pos2][fieldname]= event.target.value
		let finalevent = { target: {name: props.fieldName, value:_values, type:"component" }}
		
		props.handleChange(finalevent)
		
	};
  return (
    <Dialog fullWidth={true}  maxWidth="lg" scroll="paper" TransitionComponent={Transition} open={props.open} onClose={props.onClose} maxWidth="lg"  fullScreen={fullScreen} >
     	{isFaching ? <Loading />  : ""}
			<FileManagerDialog onSetFile={handleChange}  fieldName={fieldName} open={openFileManagerDialog}  onClose={()=>{ setOpenFileManagerDialog(false) } } />     	
      <Grid container sx={{ mt: 2, mb: 3 }}>
      	<Grid item xs={11} pl={3}  >
	              	<Typography variant="h5"
			            	 sx={{ 
		                  fontFamily: "'Open Sans', sans-serif",
		                  fontWeight: "bolder",
		                  textTransform: "none",

		                }}>
		               Crear Contenedor
			           	</Typography>  
	              </Grid>
      	<Grid item xs={1} sx={{textAlign: "right"}} >
      		<IconButton onClick={props.onClose}  aria-label="close" color="primary">
					  <CloseIcon />
					</IconButton>
      	</Grid>
      	<Grid item xs={12} >
        	<Card>
          	<Container style={{ marginTop: 20, marginBottom: 20  }}>
            	<Grid container spacing={1}>
	            	{props.value!=null &&
	            		<> 
		            		{props.value.map((mastervalue, index) => {
		            			return(
		            		 		
				            		<Grid item lg={12}>
				            			<Card>
					            			<Grid container p={2} spacing={1}>
					            			
					            				{mastervalue.childs.map((value, index) => {
					            					return(
								            			<Grid   item lg={12/mastervalue.childs.length }>
								            				<Grid container   sx={{ pb:1, pr:1} } spacing={1}>
								            				
								            					 
												              
													              <Grid item  xs={12} sx={{textAlign: "right", p:0, m:0}} >
													              {index >0?
													             		<IconButton onClick={ ()=>{deleteSplit( mastervalue.id+"_"+value.id+"_delete" ) }} aria-label="delete"   color="error">
																					  <DeleteIcon fontSize="inherit" />
																					</IconButton>
													              : <IconButton sx={{color:"#fff"}}  aria-label="important">
																					  <PriorityHighIcon fontSize="inherit" />
																					</IconButton>
													              }
													              </Grid>

																  <Grid item lg={12}>
																		<FormControl fullWidth>
																			<InputLabel>Tipo de Item</InputLabel>
																			<Select
																				key={"updown"+index}																			 
																				name= {mastervalue.id+"_"+value.id+"_updown"}
																				value={value.updown}
																				label="Posicion de la Imagen"																			
																				onChange={handleChange}
																			>
																				<MenuItem value="IMAGEUP">Imagen Arriba</MenuItem>
																				<MenuItem value="IMAGEDOWN">Imagen Abajo</MenuItem>

																				<MenuItem value="JUSTBUTTON">Solo un boton</MenuItem>
																				<MenuItem value="JUSTTITLE">Solo Titulo</MenuItem>
																				<MenuItem value="JUSTSUBTITLE">Solo Subtitulo</MenuItem>
																				<MenuItem value="JUSTIMAGE">Solo Imagen</MenuItem>
																				<MenuItem value="JUSTBODY">Solo Body</MenuItem>
																				<MenuItem value="EMPTY">Espacio Vacio</MenuItem>



																				
																				
																			</Select>
																		</FormControl>																      		 
													              </Grid>	 	

									            				  <Grid item xs={12}>
												            			<TextField
													                     	key={"title"+index}
													                      fullWidth
													                      size="small"
													                      label="Titulo" 
													                      value={value.title}
													                      name={mastervalue.id+"_"+value.id+"_title"}
													                      onChange={handleChange}
													                    />
													              </Grid>
													              <Grid item xs={12}>
																      		<TextField
													                     	key={"subtitle"+index}
													                      fullWidth
													                      size="small"
													                      label="Subtitulo" 
													                      value={value.subtitle}
													                      name= {mastervalue.id+"_"+value.id+"_subtitle"}
													                      onChange={handleChange}
													                    />
													            	</Grid>	 
																	 											            	
													            	<Grid item lg={12}>
																      	<TextField
													                     	key={"body"+index}
																			fullWidth
																			multiline
																			size="small"
																			label="Body" 
																			value={value.body}
																			rows={3}
																			name= {mastervalue.id+"_"+value.id+"_body"}
													                     	 onChange={handleChange}
													                    />
													            	</Grid>	 
													            	
													            	<Grid item xs={12} lg={6}>
																      		<TextField
													                     	key={"button"+index}
													                      fullWidth
													                      size="small"
													                      label="Boton" 
													                      value={value.button}
													                      name={mastervalue.id+"_"+value.id+"_button"} 
													                      onChange={handleChange}
													                    />
													            	</Grid>	 
													            	<Grid item xs={12} lg={6}>
																      		<TextField
													                     	key={"link"+index}
													                      fullWidth
													                      size="small"
													                      label="Link" 
													                      value={value.link}
													                      name={mastervalue.id+"_"+value.id+"_link"}  
													                      onChange={handleChange}
													                    />
													            	</Grid>	 													            	
								            						<Grid item xs={12} style={{ height: value.imagepath==""?43: "auto" }}>
												            			
												            			{value.imagepath!="" ?
																			<div className="form-image">
																           		<img 
																									key={"imagepath"+index} 
																									name={"imagepath_"+index}  
																									style={{maxHeight:"250px", maxWidth:"100%",cursor:"pointer" }}
																									onClick={()=>{ setOpenFileManagerDialog(true);setFieldName(mastervalue.id+"_"+value.id+"_imagepath"); }}
																									src={getImagePath(value.imagepath)} 
																									alt={value.imagepath}
																						/>
																			</div>
																					:
																			<TextField
																				key={"imagepath"+index}
																				fullWidth
																				size="small"
																				value={value.imagepath}
																				name={mastervalue.id+"_"+value.id+"_imagepath"}  
																				disabled
													                    	/>
																					}
																					<Button
														                type="button"
														                variant="contained"
														                color="info"
														                onClick={()=>{ setOpenFileManagerDialog(true); setFieldName(mastervalue.id+"_"+value.id+"_imagepath"); }}
														                sx={{ 
														                	position: "relative",
																							top: -33,
																							right: 3,
																							float: "right",
														                	/*mt: value.imagepath==""? "-79px": "-93px",  textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    */}}
														            	> {value.imagepath==""? "Subir Imagen": "Cambiar Imagen"}
														            	</Button>
																				</Grid>
																		</Grid>
										              </Grid>
									            	)
						      						})}  
									            
							              	<Grid  item lg={12} mr={1} sx={{textAlign: "right"}} >
							              		<Button
										                type="button"
										                variant="outlined"
										                color="error"
										                size="small"
										                name={mastervalue.id+"_split"}  
										                onClick={deleteItem}
										              	startIcon={<DeleteIcon  />}
										            	> Eliminar
										            	</Button>
								              	{mastervalue.childs.length<4 &&
												      		<Button
										                type="button"
										                variant="contained"
										                size="small"
										                color="info"
										             		sx={{ml:1}}
										                name={mastervalue.id+"_delete"}  
										                onClick={splitItem}
										                endIcon={<VerticalSplitIcon />}
										              
										            	> Dividir Contenedor (*)
										            	</Button>
										            }
											      	</Grid>
												      	
						                </Grid>  
					                </Card> 
								      	</Grid>
							      	)
						      	})}
					      	</>
				      	}
            		<Grid item xs={12} sx={{textAlign: "right"}} >
				      		<Button
		                type="button"
		                variant="contained"
		                color="info"
		                onClick={  addNewValue }
		                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
		            	> Agregar Fila
		            	</Button>
				      	</Grid>
            	
            	
	           	</Grid> 
						</Container>
          </Card>
        </Grid>   	
        <Grid item xs={6} pl={2}  pt={2} >
        	<Typography variant="body2" >
		      	(*) En la version movil solo se podra dividir hasta en 2 por fila
			    </Typography>
       	</Grid> 
        <Grid item xs={6} sx={{textAlign: "right"}} pr={2} pt={2}  >
				      		<Button
		                type="button"
		                variant="contained"
		                color="success"
		                onClick={ props.onClose }
		                sx={{ textTransform:"initial", fontWeight: {xs:"bold", md:"normal"}    }}
		            	> Aceptar
		            	</Button>
				      	</Grid>
      </Grid>
  </Dialog>
  );
};

ComponentCardContainerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ComponentCardContainerDialog;
