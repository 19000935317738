import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Box,
  Divider,
  IconButton,
  Container,
  Menu,
  AppBar,
  Paper,
  MenuList,
  MenuItem,
  Toolbar,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Autocomplete,
	Avatar,
  Stack,
  Collapse,
  Tooltip
  
} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import Check from '@mui/icons-material/Check';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Utils from "../../utils/Utils";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from "react-router-dom";
import SignInDialog from "../dialog/SignInDialog";
import CreateUserDialog from "../dialog/CreateUserDialog";
import RecoveryPassDialog from "../dialog/RecoveryPassDialog";
import ChangePassDialog from "../dialog/ChangePassDialog";
import { getCookie,setCookie} from '../../utils/Functions';
import { useSelector, useDispatch } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../redux/actions/user.actions";
import "./Header.css";
import Loading from "../Loading";
import {getUserRol} from '../../utils/Functions';
import { useLocation } from 'react-router-dom'
import SideBar from "./SideBar";
 
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

const drawerWidth = 260;

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = (props, {setOpenPanel}) => {
	
//function Header(props) {
	 
	const { user, requesting, defLang, defConfig, params,useracronym, menu } = useSelector((state) => state.Login);
	  
  const [activeLinkIndex, setActiveLinkIndex] = useState(0);
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [openChangePassDialog, setOpenChangePassDialog] = useState(false);
  
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const [openRecoveryPassDialog, setOpenRecoveryPassDialog] = useState(false);
  
  const [isLogged, setIsLogged] = useState(false);
  
  
   
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
 
  const handleMenuOption = (event) => {
  	dispatch(Actions.logout());  
  };
	
	const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  
   
  const navigate = useNavigate();
  const dispatch = useDispatch();
	const [isAuthorizeLocal ,setisAuthorizeLocal]    = React.useState(false)
  const [isAuthorizeLocalAdmin ,setisAuthorizeLocalAdmin]    = React.useState(false)
  
  
  const container = window.document.body;
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  
  const { isAuthorize,  isAuthorizeAdmin } = useSelector(
    (state) => state.Login
  );
  
   
  const location = useLocation();
	React.useEffect(()=>{
    var role = getUserRol() 
  },[isAuthorize, isAuthorizeAdmin])
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  return ( Utils.excludeSideBar.indexOf(location.pathname)>=0?"":
  	<>
		<ChangePassDialog  open={openChangePassDialog}  onClose={()=>{ setOpenChangePassDialog(false) } } />
	  	<AppBar position='fixed'  sx={{
		    width: "100%", 
		    backgroundColor: 'primary.dark' ,
		    boxShadow: 'none',
				zIndex:'1201'
		  }}>
		    <Container maxWidth='xl'>
		    {requesting ?
		    	<Loading />
		    :""}
		      <Grid
		        container 
		        sx={{
		          paddingTop: 1,
		          paddingBottom: 1,
		          display: "flex",
		          justifyContent: "center",
		          alignItems: "center"
		        }}
		      >
		        {/* Logo */}
		        <Grid item  xs={4} sx={{ display: { xs: 'flex', md: 'none' } }}  >
		         
		         	<Toolbar >
			          <IconButton
			            color="primary"
			            aria-label="open drawer"
			            edge="start"
			            onClick={handleDrawerToggle}
			            sx={{ mr: 2, color:"#fff", display: { sm: 'none' } }}
			          >
			            <MenuIcon sx={{color: '#fff', fontSize:"1.5rem"}}  />
			          </IconButton>
			          
			        </Toolbar>
		        </Grid>
		        <Grid item xs={4} md={6} lg={2}  className="navbar__logo">
		          <Button  onClick={() => { /*  dispatch(Actions.hideDashboard());*/ }}
		           component={Link} to={"/"}>
								<Typography variant="h6"
		            	 style={{
	                  color: "#fff",
	                  fontFamily: "'Open Sans', sans-serif",
	                  fontWeight: "bolder",
	                  textTransform: "none",
	                  height:30,
	                  marginTop:10

	                }}>
	               {process.env.REACT_APP_HOMETITLE}
	              </Typography>  
		          </Button>
		        </Grid>
		        {/* Menu Link */}
		        <Grid item xs={9} md={6} lg={10} sx={{ display: { xs: 'none', md: 'flex' } }}  >
		          <Grid container spacing={4} sx={{ display:'flex', justifyContent:'right' , alignItems:"center" }} >
		            {params.MenuLinks[defLang].map((menuLink, i) => {
		              const textColor = activeLinkIndex === i ? "#fff" : "#C4C4C4";
		              const fontWeight = activeLinkIndex === i ? "bold" : 400;
		              return (
		                <Grid key={"gridlink"+i } item>
		                  <Link
		                  	key={"link"+i }
		                    onClick={() => {
		                      setActiveLinkIndex(i);
		                     /* dispatch(Actions.hideDashboard());*/
		                      // navigate(menuLink.path)
		                    }}
		                    to={menuLink.path}
		                    style={{
		                      color: textColor,
		                      fontFamily: "'Open Sans', sans-serif",
		                      fontWeight: fontWeight,
		                      textTransform: "none",
		                      textDecoration: "none",
		                      display:"flex",
		                      
		                    }}
		                  >
		                    {menuLink.mainLink}
		                  </Link>
		                </Grid>
		                
		                
		              );
		            })}
		            <Grid item>
		              {/*web*/}
	                <IconButton
				            onClick={handleClick}
				            size="small"
				            sx={{ ml: 0 }}
				            aria-controls={open ? 'account-menu' : undefined}
				            aria-haspopup="true"
				            aria-expanded={open ? 'true' : undefined}
				          >
				            <Avatar sx={{ width: 32, height: 32 }}>{useracronym}</Avatar>
				          </IconButton>
				          
				          <Menu
						        anchorEl={anchorEl}
						        id="account-menu"
						        open={open}
						        onClose={handleClose}
						        onClick={handleClose}
						        PaperProps={{
						          elevation: 0,
						          sx: {
						            overflow: 'visible',
						            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						            mt: 1.5,
						            '& .MuiAvatar-root': {
						              width: 32,
						              height: 32,
						              ml: -0.5,
						              mr: 1,
						            },
						            '&:before': {
						              content: '""',
						              display: 'block',
						              position: 'absolute',
						              top: 0,
						              right: 14,
						              width: 10,
						              height: 10,
						              bgcolor: 'background.paper',
						              transform: 'translateY(-50%) rotate(45deg)',
						              zIndex: 0,
						            },
						          },
						        }}
						        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						      >
						        <MenuItem>
						          <Avatar /> {defConfig[defLang].myaccountlabel}
						        </MenuItem>
						        
						        <Divider />
						         {/*
						        <MenuItem>
						          <ListItemIcon>
						            <Settings fontSize="small" />
						          </ListItemIcon>
						          Ajustes
						        </MenuItem>
						      	*/}			

								<MenuItem onClick={()=>{ setOpenChangePassDialog(true)}} >
						          <ListItemIcon>
						            <Settings fontSize="small" />
						          </ListItemIcon>
						          {defConfig[defLang].changepasslabel}
						        </MenuItem>

						        <MenuItem onClick={handleMenuOption} >
						          <ListItemIcon >
						            <Logout fontSize="small" />
						          </ListItemIcon>
						          {defConfig[defLang].logoutlabel}
						        </MenuItem>
						      </Menu>
		                
		            </Grid>
		          </Grid>
		        </Grid>
		        
		        <Grid item xs={4} sx={{ display: { xs: 'flex', md: 'none', justifyContent:"right" }  }}  >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                 <AccountCircle sx={{color: '#fff', fontSize:"1.5rem"}} />
              </IconButton>
              {/*mobile*/}
							<IconButton
		            onClick={handleClick}
		            size="small"
		            sx={{ ml: 2 }}
		            aria-controls={open ? 'account-menu' : undefined}
		            aria-haspopup="true"
		            aria-expanded={open ? 'true' : undefined}
		          >
		            <Avatar sx={{ width: 32, height: 32 }}>{useracronym}</Avatar>
		          </IconButton>
		          
		          <Menu
				        anchorEl={anchorEl}
				        id="account-menu"
				        open={open}
				        onClose={handleClose}
				        onClick={handleClose}
				        PaperProps={{
				          elevation: 0,
				          sx: {
				            overflow: 'visible',
				            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
				            mt: 1.5,
				            '& .MuiAvatar-root': {
				              width: 32,
				              height: 32,
				              ml: -0.5,
				              mr: 1,
				            },
				            '&:before': {
				              content: '""',
				              display: 'block',
				              position: 'absolute',
				              top: 0,
				              right: 14,
				              width: 10,
				              height: 10,
				              bgcolor: 'background.paper',
				              transform: 'translateY(-50%) rotate(45deg)',
				              zIndex: 0,
				            },
				          },
				        }}
				        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				      >
				        <MenuItem>
				          <Avatar /> {defConfig[defLang].myaccountlabel}
				        </MenuItem>
				        
				        <Divider />
				        
						<MenuItem onClick={()=>{ setOpenChangePassDialog(true)}} >
						          <ListItemIcon>
						            <Settings fontSize="small" />
						          </ListItemIcon>
						          {defConfig[defLang].changepasslabel}
						        </MenuItem>

				        <MenuItem onClick={handleMenuOption}>
				          <ListItemIcon>
				            <Logout fontSize="small" />
				          </ListItemIcon>
				          {defConfig[defLang].logoutlabel}
				        </MenuItem>
				      </Menu>
		           
		        </Grid>
		      </Grid>
		    </Container>
			</AppBar>
			
			 <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', backgroundColor: "primary.main", width: drawerWidth },
          }}
        >
        	<Toolbar />
		      <List sx={{backgroundColor: "primary.main",  color:"#fff" }}>
		      	<SideBar menu={menu} />
		      </List>
        </Drawer> 
			 
			
				<Paper className='fixed-container' sx={{ minHeight:560, 
						boxShadow:"0px 0px 0px 0px", borderRadius:0, 
						backgroundColor:"primary.main", color:"#fff", 
		        height: /*windowHeight-68,*/ 'auto',
		        /*overflowY: "scroll",*/
						minWidth: "16%", display:{xs: 'none', md:'block', lg:'block'} }}>
		      <SideBar menu={menu} />
		    </Paper>
			 
		</>
  );
};


Header.propTypes = {
  window: PropTypes.func,
};


export default Header;
