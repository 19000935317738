import React, { useState, useCallback, useEffect } from 'react';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { 
	Typography,
	Box,
	LinearProgress
} from "@mui/material"; 
import { styled } from '@mui/material/styles';
const ProgressBar = (props) => {	
	
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: props.height?props.height:35,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ?  theme.palette.warning.dark : theme.palette.warning.light,
  },
}));
  
	return (
		<Box sx={props.sx?props.sx:{}} >
			<BorderLinearProgress  variant="determinate" value={props.value} />
			<Box
        sx={{
          top: props.height?(props.height-5)* (-1) :-30 ,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" component="div" color="#fff" fontWeight="bolder">
          {props.label}
        </Typography>
      </Box>
    </Box>
	);
};


ProgressBar.propTypes = {};

export default ProgressBar;
