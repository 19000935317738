import React from "react";
import PropTypes from "prop-types";
import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import Utils from "../../utils/Utils";
import { Box } from "@mui/system";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useLocation } from 'react-router-dom'
const Footer = (props) => {
	const location = useLocation();
	const bull = (
	  <Box  component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
	    •
	  </Box>
	);
	const { defLang, defConfig, params } = useSelector((state) => state.Login);
	
  return ( location.pathname=="/login"?"":
	  <>
	  	 
	  
	    <Grid container sx={{  backgroundColor: 'secondary.main',   }}>
	      <Container>
	        <Grid container spacing={2}   >
						<Grid item lg={6} xs={6} sx={{mt:1, mb:1}} >
							<Typography variant="h6"
							  sx={{ 
							    color:"#fff",
							    fontSize: {xs:"0.625rem", lg:"1rem"},
							  }}
							>
							 © {params.conpanyInfo}
							</Typography>
	          </Grid>
	          <Grid item lg={6} xs={6} sx={{mt:1, mb:1}} >
		        	<Typography variant="h6"
		            sx={{
		            	textAlign: "right",
		            	fontSize: {xs:"0.625rem" , lg:"1rem" },
		              color:"#fff"
		            }}
	            >  
	            	{params.footerLinks[defLang].map((menu, index) => {
			            return ( 
			               <span key={"p"+index} >	{index==1?bull:""}	{menu.mainLink} </span>
			            );
			          })}
		          </Typography>
	          </Grid> 
	           
	        
	           
	        </Grid> 
	      </Container>
	    </Grid>
	  </>
  );
};

Footer.propTypes = {};

export default Footer;
