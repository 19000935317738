import ApiClient from "../api/ApiClient";
import actionTypes from "../redux/action.types";
import * as Actions from "../redux/actions/process.actions";
import EndPoint from "../api/Endpoints";
import { getCookie,setCookie, preloadForm, parseFormInfo, prepareForm, mergeFormData, prepareComponentConfig, simpleGetData, simplePostData} from '../utils/Functions';
import { toast } from "react-toastify";
export const processData = async (dispatch, componentName, action, data) => {
  try {
    const response = await ApiClient.doPut("/"+componentName+"/"+action , data);
    if (response.status) {
   	const reloadData = await prepareComponentConfig(componentName)  
   	//console.log("reloadData",reloadData)
    	if (reloadData.status){
    		response["componentConfig"] = reloadData.componentConfig
    	}
    	dispatch({ type: actionTypes.PROCESS_SUCCESS, payload: response });
    } else {
    	dispatch({ type: actionTypes.PROCESS_FAILED, payload: response });
    }
  } catch (error) {
  	//console.log(">>>>ERR:", error)
  	dispatch({ type: actionTypes.PROCESS_FAILED, payload: {message: "Internal Error:"+error} });
    
  }
}

export const bulkData = async (dispatch, componentName, data) => {
	try {
	  const response = await ApiClient.doPost(EndPoint["bulkData"], data);
	  if (response.status) {		  
		  dispatch({ type: actionTypes.BULK_PROCESS_SUCCESS, payload: response });
	  } else {
		  dispatch({ type: actionTypes.PROCESS_FAILED, payload: response });
	  }
	} catch (error) {
		//console.log(">>>>ERR:", error)
		dispatch({ type: actionTypes.PROCESS_FAILED, payload: {message: "Internal Error:"+error} });
	  
	}
  }
  

export const FetchFiles = async (data)=>{
  try{
  	//console.log("list files", EndPoint.getFiles)
    const response = await ApiClient.doPost(EndPoint.getFiles, data);
    return response;
  }catch(e){
    return {status:false,message:e.message,data:[]};
  }
}
export const createFileFolder = async (data)=>{
  try{
    const response = await ApiClient.doPost(EndPoint.createFileFolder, data);
    return response;
  }catch(e){
    return {status:false,message:e.message,data:[]};
  }
}
export const uploadFile = async (data, filename)=>{
	
	//new
  try{
    var resp = await ApiClient.doPost(EndPoint.uploadFile, data, true);
 	  return resp;
  }catch(e){
    return {status:false,message:e.message,data:[]};
  }
}
export const deleteFile = async (data)=>{
  try{
    const response = await ApiClient.doPost(EndPoint.deleteFile, data);
    return response;
  }catch(e){
    return {status:false,message:e.message,data:[]};
  }
}

export const getFormData = async (dispatch, data, apiName ) => {
  try {
  	
  	const validation = await prepareComponentConfig(data.code)  
		if(validation.componentConfig.writable ){
			const response = await ApiClient.doPost(EndPoint["getDataByCodeLanguage"], data);
	    if (response.status) {
	    	let formData= await prepareForm (response.data.CONFIG, data)
	    	/*
	    	if (Array.isArray(response.data.CONFIG)){
	    		formData= response.data.CONFIG
	    		let resp =await preloadForm(formData)	
	    	}else{
	    		formData =await parseFormInfo(response.data.CONFIG)	
	    	}
	    	if(data.id){
	    		if(data.id!="new"){    			
	    			let row = await ApiClient.doPost(data.code+"/"+"get-single", { id:data.id });
	    			if (row.status){
	    				formData = mergeFormData (formData, row.data)	
	    			}
	    		}	
	    	}
	    	if (response.data.CONFIG.datapostload){
	    		if ((response.data.CONFIG.datapostload+"").length>0){
	    			try{
	    				//public apis 
	    				const {simpleGetData} = require("../utils/Functions");
	    				const {simplePostData} = require("../utils/Functions");

		 					await eval(response.data.CONFIG.datapostload);
		 				}catch(Exc){
		 					console.log("EXC:", Exc)
		 					toast.error("<Error postload Action>"+Exc);	
		 				}
		    	}
	    	}
	    	*/
			console.log(validation.componentConfig)
			  dispatch({ type: actionTypes.FORM_LOADED, payload: {formData:formData, componentConfig : validation.componentConfig } });
	    }else{
	    	dispatch({ type: actionTypes.FORM_ERROR  });
	    }
	  }else{
	  	dispatch({ type: actionTypes.FORM_ERROR  });
	  }
  } catch (error) {
  	//console.log(">>>>", error)
    dispatch({ type: actionTypes.FORM_ERROR  });
  }
}

export const getComponentConfig = async (dispatch, data, type) => {
  try {
    const response = await prepareComponentConfig(data, type)  
    //console.log(response)
    
    if (response.status) {
		  dispatch({ type: actionTypes.COMPONENT_LOADED, payload: response.componentConfig });
    } else {
    	dispatch({ type: actionTypes.COMPONENT_ERROR, payload: response });
    }
    
    //dispatch({ type: actionTypes.COMPONENT_ERROR, payload: response });
  } catch (error) {
  	//console.log("errr", error)
  	dispatch({ type: actionTypes.COMPONENT_ERROR, error: error });
  }
}